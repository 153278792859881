const { URL_PREFIXES } = require("../../components/templates-generator/fields/homepage-url/homepage-url-service");
const startsWith = require("lodash/startsWith");
const getHasFreeTier = (options = {})=>{
    const { freeTierForcePrevent, asPath, hasFreeTierCookie, hasStudentPlan } = options;
    // QS to prevent free tier
    if (freeTierForcePrevent) return false;
    // Student plan is private case of free tier
    if (hasStudentPlan) return true;
    // Free tier landing pages start with /ft/. we want to force free tier for them.
    if (asPath && startsWith(asPath, URL_PREFIXES.FREE_TIER)) return true;
    // Platform indication.
    if (hasFreeTierCookie) return hasFreeTierCookie === "true";
    return true;
};
module.exports = {
    getHasFreeTier
};
