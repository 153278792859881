function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const mondaySecrets = require("@mondaydotcomorg/monday-secrets");
const store = mondaySecrets.getStore("homepage", process.env.CREDS_ENV || "development");
let _initializedStore;
const initStore = function() {
    var _ref = _async_to_generator(function*() {
        yield store.init();
        _initializedStore = store;
    });
    return function initStore() {
        return _ref.apply(this, arguments);
    };
}();
const initStoreIfNeeded = function() {
    var _ref = _async_to_generator(function*() {
        if (!_initializedStore) {
            yield initStore();
        }
    });
    return function initStoreIfNeeded() {
        return _ref.apply(this, arguments);
    };
}();
const getStore = function() {
    var _ref = _async_to_generator(function*() {
        yield initStoreIfNeeded();
        return _initializedStore;
    });
    return function getStore() {
        return _ref.apply(this, arguments);
    };
}();
const get = function() {
    var _ref = _async_to_generator(function*(secretName) {
        const secretsStore = yield getStore();
        const secret = secretsStore.get(secretName);
        return secret || {};
    });
    return function get(secretName) {
        return _ref.apply(this, arguments);
    };
}();
const getSync = (secretName)=>{
    if (!_initializedStore) {
        console.log(`getSync was called without initializing the store. Secret name: ${secretName}`);
        initStore();
    }
    const secret = store.getSync(secretName);
    return secret || {};
};
module.exports = {
    initStore,
    get,
    getSync
};
