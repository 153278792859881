function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const { trackBigBrainEventOnServer } = require("/server/services/bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require('./providers-keys');
const { CLUSTER_INTERNAL_FETCH_ERROR } = require("/constants/bigbrain-event-types");
const REFRESH_INTERVAL_MINUTES = 15 * 60;
const URL_BASE = process.env.MONDAY_BASE_PATH;
const CLUSTER_CONFIG_URL_BASE = `${URL_BASE}/clusters/get-cluster-config`;
class ClusterConfigDataProvider extends BaseDataProvider {
    key() {
        const { clusterId, subClusterId, locale } = this.params();
        return `${ProviderKeys.CLUSTER_CONFIG}_cluster_${clusterId}_subCluster_${subClusterId}_locale_${locale}`;
    }
    refreshInterval() {
        return REFRESH_INTERVAL_MINUTES;
    }
    getData() {
        var _this = this;
        return _async_to_generator(function*() {
            const { clusterId, subClusterId, locale } = _this.params();
            try {
                const options = {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "GET"
                };
                const searchParams = new URLSearchParams({
                    clusterId,
                    subClusterId,
                    locale
                });
                const url = `${CLUSTER_CONFIG_URL_BASE}?${searchParams}`;
                const res = yield fetch(url, options);
                if ((res === null || res === void 0 ? void 0 : res.status) === 200) {
                    const response = yield res.json();
                    return response;
                } else {
                    throw new Error(res.statusText);
                }
            } catch (e) {
                trackBigBrainEventOnServer(CLUSTER_INTERNAL_FETCH_ERROR, {
                    direct_object: e.message
                });
                console.error(e.message);
                throw e;
            }
        })();
    }
    constructor(params){
        super(params);
    }
}
export default ClusterConfigDataProvider;
