function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const { differenceInSeconds } = require("date-fns");
const { getAsync, setAsync, delAsync } = require("../redis-service/redis-service");
const { initLogger } = require("../logger-service/logger-service");
const Sentry = require("@sentry/nextjs");
const toCacheable = (provider)=>{
    // Provider and cache properties
    const key = provider.key();
    const params = provider.params();
    const refreshInterval = provider.refreshInterval();
    const cacheExpirationTime = provider.cacheExpirationTime();
    // Cache keys
    const CACHE_PREFIX = "homepage::cache_service";
    const cacheKey = `${CACHE_PREFIX}::${key}`;
    // Logger init
    const logger = initLogger();
    const metadata = {
        provider: key,
        params,
        cacheKey
    };
    const shouldFetch = (value)=>{
        const lastUpdatedAt = value && value.lastUpdatedAt;
        return differenceInSeconds(new Date(), lastUpdatedAt) > refreshInterval;
    };
    const invalidate = function() {
        var _ref = _async_to_generator(function*() {
            try {
                yield delAsync(cacheKey);
            } catch (error) {
                logger.error(_object_spread_props(_object_spread({}, metadata), {
                    error
                }), `cacheService:::invalidate failed`);
                throw error;
            }
        });
        return function invalidate() {
            return _ref.apply(this, arguments);
        };
    }();
    const fetch = function() {
        var _ref = _async_to_generator(function*() {
            let data = yield provider.getData();
            if (!provider.validate(data)) {
                Sentry.captureMessage(`Failed to validate new data in cache-service for ${key}}`);
                throw new Error("Validation failed for new data");
            }
            const cacheData = JSON.stringify({
                data,
                lastUpdatedAt: new Date()
            });
            yield setAsync(cacheKey, cacheData, "EX", cacheExpirationTime);
            return data;
        });
        return function fetch() {
            return _ref.apply(this, arguments);
        };
    }();
    const getData = function() {
        var _ref = _async_to_generator(function*() {
            try {
                const cacheValue = yield getAsync(cacheKey);
                let response = null;
                // Cache hit
                if (cacheValue) {
                    logger.info(metadata, "cacheService:::fetch cache hit");
                    let value;
                    let forceFetch = false;
                    // Try to parse the value in cache, if it fails, try to regenerate in order to get a new copy
                    // Might happen with corrupted json received from getData
                    try {
                        value = JSON.parse(cacheValue);
                        response = value.data;
                    } catch (error) {
                        logger.error(_object_spread_props(_object_spread({}, metadata), {
                            error
                        }), `cacheService:::fetch forced regenerating because of invalid json`);
                        forceFetch = true;
                    }
                    // Check if should be refreshed, even if value exists in cache
                    if (forceFetch || shouldFetch(value)) {
                        logger.info(_object_spread_props(_object_spread({}, metadata), {
                            forceFetch
                        }), "cacheService:::fetch re-fetching");
                        // Should be parallel (better move to sidekiq or any other background job)
                        try {
                            response = yield fetch();
                        } catch (error) {
                            logger.error(_object_spread_props(_object_spread({}, metadata), {
                                forceFetch,
                                error
                            }), `cacheService:::fetch failed while re-fetching`);
                        }
                    }
                } else {
                    logger.info(metadata, `cacheService:::fetch cache miss`);
                    response = yield fetch();
                }
                return response;
            } catch (error) {
                logger.error(error, `cacheService:::getData failed`);
                throw error;
            }
        });
        return function getData() {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        getData,
        invalidate
    };
};
module.exports = {
    toCacheable
};
