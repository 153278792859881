import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
export const isKeyPressOnCustomControlClickEvent = (e)=>{
    return (e === null || e === void 0 ? void 0 : e.key) === "Enter" || (e === null || e === void 0 ? void 0 : e.key) === " " || (e === null || e === void 0 ? void 0 : e.key) === "Spacebar" || (e === null || e === void 0 ? void 0 : e.which) === 13 || (e === null || e === void 0 ? void 0 : e.which) === 32;
};
export const generateOnKeyDownHandler = (onClick, ...parameters)=>{
    return (event)=>{
        if (isKeyPressOnCustomControlClickEvent(event)) {
            event.preventDefault();
            onClick(event, ...parameters);
        }
    };
};
// the 'mousedown' event also emits 'focus' by default,
// so if we want to prevent focusing a keyboard-navigable element on click, we just need to catch that event.
export const preventFocusOnMouseDown = (event)=>event.preventDefault();
export const setSVGTitle = (containingSelector, title)=>{
    const specialCharactersRegex = /[^\w\s]/gi;
    let cleanSelector = containingSelector.replace(specialCharactersRegex, "");
    // bug fix:
    if (containingSelector.startsWith("#") && !cleanSelector.startsWith("#")) {
        cleanSelector = `#${cleanSelector}`;
    }
    const element = document.querySelector(`${cleanSelector} svg > title`);
    if (element === null || element === void 0 ? void 0 : element.textContent) {
        element.textContent = title;
    }
};
const ACCESSIBLE_COLOR_TRANISTIONS = {
    pink: BRAND_BLACK_TEXT_COLOR
};
export const getAccessibleButtonColor = (color)=>{
    return ACCESSIBLE_COLOR_TRANISTIONS[color] || color;
};
