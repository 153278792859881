// Retina display
export const RETINA_RATIO_QUERY = "(min-device-pixel-ratio: 2)";
// Max widths
export const XXS = 360;
export const SMALL_PHONE_BREAK = 380; // allow 390px to be in the next level
export const XS_M = 450;
export const XS = 768;
export const SM = 991;
export const MD = 1199;
export const MD_DESKTOP = 1440;
export const LG = 1599;
export const X_LG = 1880;
export const MAX_DESKTOP_CONTENT_WIDTH = 1050;
export const MAX_DESKTOP_GRID_WIDTH = 1300;
export const MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH = 1440;
export const FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH = 1920;
export const MID_HEADER_DESKTOP_MENU = 1280;
export const MIN_HEADER_DESKTOP_MENU = 1229;
export const HEADER_DESKTOP_MENU_NARROW_SCREEN = 1300;
export const XXS_QUERY = `(max-width: ${XXS}px)`;
export const XS_QUERY = `(min-width: ${XXS + 1}px) and (max-width: ${XS}px)`;
export const SM_QUERY = `(min-width: ${XS + 1}px) and (max-width: ${SM}px)`;
export const MD_QUERY = `(min-width: ${SM + 1}px) and (max-width: ${MD}px)`;
export const LG_QUERY = `(min-width: ${MD + 1}px) and (max-width: ${LG}px)`;
export const XL_QUERY = `(min-width: ${LG + 1}px)`;
export const MAC_BOOK_PRO = `(max-width: ${MD}px)`;
export const ALTERNATE_MAC_BOOK_PRO_MAX_WIDTH_QUERY = `1441px`;
export const MOBILE_QUERY_MAX_WIDTH = `${XS}px`;
export const MOBILE_SMALL_PHONE_MAX_WIDTH = `${SMALL_PHONE_BREAK}px`;
export const MD_DESKTOP_WIDTH = `${MD_DESKTOP}px`;
export const MOBILE_QUERY_MIN_WIDTH = `${XS_M + 1}px`;
export const MOBILE_QUERY_MAX_WIDTH_M = `${XS_M}px`;
export const MOBILE_QUERY = `(max-width: ${MOBILE_QUERY_MAX_WIDTH})`;
export const MOBILE_RETINA_QUERY = MOBILE_QUERY + " and " + RETINA_RATIO_QUERY;
export const SMALL_MOBILE_QUERY_MAX_WIDTH = `${XXS}px`;
export const SMALL_MOBILE_QUERY_INCLUDED_MAX_WIDTH = `${XXS - 1}px`;
export const TABLET_QUERY_MIN_WIDTH = `${XS + 1}px`;
export const TABLET_QUERY_MAX_WIDTH = `${SM}px`;
export const TABLET_QUERY_MAX_WIDTH_MEDIA_QUERY = `(max-width: ${SM}px)`;
export const MACBOOK_PRO_MAX_WIDTH = `${MD}px`;
export const FOLD_TEST_MAX_WIDTH = "1270px";
export const MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH = `${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`;
export const HEADER_DESKTOP_MENU_MIN_WIDTH = `${MIN_HEADER_DESKTOP_MENU}px`;
export const HEADER_DESKTOP_MENU_MID_WIDTH = `${MID_HEADER_DESKTOP_MENU}px`;
export const HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH = `${HEADER_DESKTOP_MENU_NARROW_SCREEN}px`;
export const LARGE_SCREEN_MIN_WIDTH = `${LG}px`;
export const X_LARGE_SCREEN_MIN_WIDTH = `${X_LG}px`;
export const TABLET_QUERY = MD_QUERY;
export const TABLET_RETINA_QUERY = TABLET_QUERY + " and " + RETINA_RATIO_QUERY;
