//TODO - Maybe use layers instead
export const MODAL_BACKGROUND = 999;
export const SIDE_NAV = 666;
export const TOP_BAR = 555;
export const TOP_BAR_SHADOW = 557;
export const CATEGORY = 999;
export const TOP_LAYER = 9;
export const ZERO = 0;
export const HIDDEN = -1;
export const LAYER_ONE = 100;
export const LAYER_TWO = 200;
export const ALWAYS_ON_TOP = 10000000;
export const COOKIES_BANNER = 1031;
export const LANGUAGE_PICKER_MENU = 1032;
export const BASE_TEMPLATE_ROOT = 1;
export const TEMPLATE_GENERATOR_ACTION_BUTTONS = 1;
// CANVAS
export const CANVAS_PREVIEW = 1;
export const SSR_CANVAS = 1;
export const REACT_CANVAS = -1;
