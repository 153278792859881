import { fetchUserAgent, isMobileOrTabletViewer, isAndroidViewer, isFacebookViewer, isInstagramViewer } from "services/user-agent-service";
export const isAndroid = (userAgentParam)=>{
    const userAgent = userAgentParam || fetchUserAgent();
    return isAndroidViewer(userAgent);
};
export const isMobile = (userAgentParam)=>{
    const userAgent = userAgentParam || fetchUserAgent();
    return isMobileOrTabletViewer(userAgent);
};
export const isFacebook = (userAgentParam)=>{
    const userAgent = userAgentParam || fetchUserAgent();
    return isFacebookViewer(userAgent);
};
export const isInstagram = (userAgentParam)=>{
    const userAgent = userAgentParam || fetchUserAgent();
    return isInstagramViewer(userAgent);
};
export const isMeta = (userAgentParam)=>{
    return isFacebook(userAgentParam) || isInstagram(userAgentParam);
};
export const appendUrlSchemeIfNeeded = (url)=>{
    let urlScheme = "";
    let urlSuffix = "";
    // https://stackoverflow.com/questions/64934340/google-sign-in-not-working-in-facebook-in-app-browser
    // force open in external browser
    if (isMeta() && isAndroid()) {
        urlScheme = "intent:https:";
        urlSuffix = "#Intent;end";
    }
    return `${urlScheme}${url}${urlSuffix}`;
};
