const URL_PREFIXES = {
    LP: "/lp/",
    FREE_TIER: "/ft/",
    SEO: "/s/",
    TEMPLATES: "/templates/",
    GO_TEMPLATES: "/go-templates/",
    TERMS: "/terms/",
    SECURITY: "/security/",
    PARTNERS: "/partners/",
    CS: "/cs/",
    INTEGRATIONS: "/integrations/",
    PODCASTS: "/pod/",
    VISION: "/vision/",
    LANG: "/lang/",
    EMAILS: "/emails/",
    HR: "/hr/",
    TEST: "/test/",
    DEVELOPERS: "/developers/",
    REFLECT: "/reflect/"
};
module.exports = {
    URL_PREFIXES
};
