function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const getCachedData = function() {
    var _ref = _async_to_generator(function*(providerInstance) {
        const cacheService = require("../cache-service");
        const cachedProvider = cacheService.toCacheable(providerInstance);
        const data = yield cachedProvider.getData();
        return data;
    });
    return function getCachedData(providerInstance) {
        return _ref.apply(this, arguments);
    };
}();
class BaseDataProvider {
    // The unique identifier of the provider
    key() {
        throw new Error("You must supply provider's key");
    }
    // The method use to fetch the data
    getData() {
        return _async_to_generator(function*() {
            throw new Error("You must supply provider's key");
        })();
    }
    // The parameters received for fetching the data
    params() {
        return this._params || {};
    }
    // The interval in which the data will be refreshed, in seconds
    refreshInterval() {
        return 60; // a minute
    }
    // The duration the cache value will remain valid, in seconds
    cacheExpirationTime() {
        return 60 * 60 * 24 * 7; // 7 days
    }
    // The method to validate the data
    validate(data) {
        return true;
    }
    constructor(params){
        this._params = params;
    }
}
module.exports = {
    BaseDataProvider,
    getCachedData
};
