const COMMUNITY_URL_PREFIX = process.env.COMMUNITY_URL_PREFIX;
const isDevelopment = process.env.ENV == "DEV";
const MONDAY_STORIES_SLUG_URL = process.env.MONDAY_STORIES_SLUG_URL;
const getTemplateUrl = (templateId)=>{
    if (!templateId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/show/${templateId}`;
};
const getTemplateUrlWithNextTemplates = (templateId, nextTemplatesIds)=>{
    if (!templateId) {
        return null;
    }
    if (!nextTemplatesIds || nextTemplatesIds.length == 0) {
        return getTemplateUrl(templateId);
    }
    return `${getTemplateUrl(templateId)}?next=${nextTemplatesIds.join()}`;
};
const getTemplateEditUrl = (templateId)=>{
    if (!templateId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/edit/${templateId}`;
};
const getTemplateEditSelfie = (templateId)=>{
    if (!templateId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/edit-selfie/${templateId}`;
};
const getTemplateEditBoardScreenshot = (templateId)=>{
    if (!templateId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/edit-board-screenshot/${templateId}`;
};
const getUserTemplatesUrl = (userId)=>{
    if (!userId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/user/${userId}`;
};
const getAccountTemplatesUrl = (accountId)=>{
    if (!accountId) {
        return null;
    }
    return `${COMMUNITY_URL_PREFIX}/stories/templates/account/${accountId}`;
};
const getAdminPageUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/templates/admin`;
};
const getTop5CategoryPageUrl = (category)=>{
    return `${COMMUNITY_URL_PREFIX}/stories/templates/top5/${category}`;
};
const getShareNewTemplateUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/templates/new`;
};
const getUserPagesUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/user/pages`;
};
const getUserApprovalPageUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/user/approval`;
};
const isUserPagesUrl = (url)=>{
    return url && url.endsWith("/stories/user/pages");
};
const fixDevImageUrl = (src)=>{
    if (isDevelopment && src && (src.startsWith("/static/") || src.startsWith("/Generator_featured images"))) {
        // in local host - change the source url to mondaystaging.com
        src = "https://mondaystaging.com" + src;
    }
    return src;
};
const addBasePathToUrl = (src)=>{
    src = fixDevImageUrl(src);
    if (src && !src.startsWith("http")) {
        src = `${process.env.MONDAY_BASE_PATH}${src}`;
    }
    return src;
};
const getIntercomConversationUrl = (appId, userId)=>{
    return `https://app.intercom.io/a/apps/${appId}/users/${userId}/all-conversations`;
};
const getMondayStoriesBoardUrl = (boardId)=>{
    return `${MONDAY_STORIES_SLUG_URL}/boards/${boardId}`;
};
const getMondayStoriesEmbeddedBoardUrl = (boardId)=>{
    return `${MONDAY_STORIES_SLUG_URL}/embedded_boards_json/${boardId}?clean-board=true&allow-edit=true`;
};
const getMondayBoardUrl = (boardId, slug)=>{
    return `${MONDAY_STORIES_SLUG_URL.replace("mondaystories", slug)}/boards/${boardId}`;
};
const getSlugUseTemplateUrl = (slug)=>{
    return MONDAY_STORIES_SLUG_URL.replace("mondaystories", slug) + "/stories/board_from_story";
};
const getHomeUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories`;
};
const getLoginUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/authentication/signin`;
};
const getSignupUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/signup`;
};
const getLandingPageWithoutAutoLoginUrl = ()=>{
    return `${COMMUNITY_URL_PREFIX}/stories/u_landing`;
};
module.exports = {
    getTemplateUrl,
    getTemplateUrlWithNextTemplates,
    getTemplateEditUrl,
    getUserTemplatesUrl,
    getUserApprovalPageUrl,
    getAccountTemplatesUrl,
    getAdminPageUrl,
    fixDevImageUrl,
    getShareNewTemplateUrl,
    getUserPagesUrl,
    isUserPagesUrl,
    getTemplateEditSelfie,
    getTemplateEditBoardScreenshot,
    getIntercomConversationUrl,
    getMondayStoriesBoardUrl,
    getMondayBoardUrl,
    getMondayStoriesEmbeddedBoardUrl,
    getSlugUseTemplateUrl,
    getHomeUrl,
    getLoginUrl,
    getSignupUrl,
    getLandingPageWithoutAutoLoginUrl,
    getTop5CategoryPageUrl,
    addBasePathToUrl
};
