const redis = require("redis");
const { promisify } = require("util");
let _client;
const clientMethods = [
    "get",
    "mget",
    "set",
    "setex",
    "lpush",
    "lrange",
    "ltrim",
    "lrem",
    "sadd",
    "smembers",
    "sismember",
    "hset",
    "hget",
    "hgetall",
    "del",
    "srem",
    "scan",
    "exists"
];
const getClient = ({ callerFunctionName = "Unknown function", callerFunctionParams = [] })=>{
    if (!_client) {
        console.log(`Warning: ${callerFunctionName} with params: ${callerFunctionParams} is called before Redis client is initialized. Initializing client.`);
        init();
    }
    return _client;
};
const init = ()=>{
    const { logInfo } = require("../../logging/logger");
    logInfo("Initializing Redis");
    if (_client) {
        logInfo("Init Redis was called, although it's already initialized.");
        return;
    }
    const { getSync } = require("../../../secrets-service");
    const { port = process.env.REDIS_OVERRIDE_PORT, hostname = process.env.REDIS_OVERRIDE_HOSTNAME, password = process.env.REDIS_OVERRIDE_PASSWORD || "" } = getSync("redis");
    _client = redis.createClient(port, hostname, {
        no_ready_check: true
    });
    _client.auth(password, function(err) {
        if (err) throw err;
    });
    _client.on("connect", function() {
        console.log(`Connected to Redis on port ${port}`);
    });
    _client.on("error", function(err) {
        console.log(`Error ${err}`);
    });
    _client.promsifiedMethods = {};
    clientMethods.forEach((methodName)=>{
        _client.promsifiedMethods[methodName] = promisifyMethod(_client, methodName);
    });
    setSIGINTListener();
};
const promisifyMethod = (client, methodName)=>{
    const promisifed = promisify(client[methodName]).bind(client);
    // put it on instance for sequlize cache
    client[`${methodName}Async`] = promisifed;
    return promisifed;
};
const getPromisifiedMethod = (methodName)=>{
    const client = getClient({
        callerFunctionName: "getPromisifiedMethod",
        callerFunctionParams: [
            methodName
        ]
    });
    if (!client.promsifiedMethods[methodName]) {
        console.log(`Promisified method ${methodName} does not exist. Creating it.`);
        client.promsifiedMethods[methodName] = promisifyMethod(client, methodName);
    }
    return client.promsifiedMethods[methodName];
};
const setSIGINTListener = ()=>{
    process.on("SIGINT", function() {
        console.log("SIGINT received - stopping Redis client");
        getClient({
            callerFunctionName: "setSIGINTListener"
        }).quit();
    });
};
module.exports = {
    init,
    getAsync: (...args)=>getPromisifiedMethod("get")(...args),
    mgetAsync: (...args)=>getPromisifiedMethod("mget")(...args),
    setAsync: (...args)=>getPromisifiedMethod("set")(...args),
    setexAsync: (...args)=>getPromisifiedMethod("setex")(...args),
    lpushAsync: (...args)=>getPromisifiedMethod("lpush")(...args),
    lrangeAsync: (...args)=>getPromisifiedMethod("lrange")(...args),
    ltrimAsync: (...args)=>getPromisifiedMethod("ltrim")(...args),
    lremAsync: (...args)=>getPromisifiedMethod("lrem")(...args),
    saddAsync: (...args)=>getPromisifiedMethod("sadd")(...args),
    smembersAsync: (...args)=>getPromisifiedMethod("smembers")(...args),
    sismemberAsync: (...args)=>getPromisifiedMethod("sismember")(...args),
    hsetAsync: (...args)=>getPromisifiedMethod("hset")(...args),
    hgetAsync: (...args)=>getPromisifiedMethod("hget")(...args),
    hgetallAsync: (...args)=>getPromisifiedMethod("hgetall")(...args),
    delAsync: (...args)=>getPromisifiedMethod("del")(...args),
    sremAsync: (...args)=>getPromisifiedMethod("srem")(...args),
    scanAsync: (...args)=>getPromisifiedMethod("scan")(...args),
    existsAsync: (...args)=>getPromisifiedMethod("exists")(...args)
};
