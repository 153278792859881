// Used for GTM
module.exports = {
    HUBSPOT_FORM_SUBMITTED: "hubspotFormSubmitted",
    IN_PAGE_AB_TEST_EVENT_NAME: "inPageAbTest",
    SHOW_COOKIEHUB_DIALOG_EVENT_NAME: "showCookiehubDialog",
    TRIGGER_COOKIEHUB_DIALOG_EVENT_NAME: "triggerCookiehubDialog",
    SIGNUP_CLICKED: "signupClicked",
    CLICK_MAIN_NAV: "click_main_nav",
    FAQ_ACCORDION: "faq_accordion",
    SIGNUP_WITH_GOOGLE_CLICKED: "signupWithGoogleClicked",
    TAG_SELECTED: "tagSelected",
    META_DATA: "meta_data"
};
