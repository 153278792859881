export const CLUSTERS = "clusters";
export const UTM_ADGROUP = "utm_adgroup";
export const UTM_BANNER = "utm_banner";
export const UTM_CAMPAIGN = "utm_campaign";
export const UTM_KEYWORD = "utm_keyword";
export const UTM_MEDIUM = "utm_medium";
export const UTM_SOURCE = "utm_source";
export const SIGNUP_REFERRER = "signup_referrer";
export const LANDING_PAGE = "landing_page";
module.exports = {
    CLUSTERS,
    UTM_ADGROUP,
    UTM_BANNER,
    UTM_CAMPAIGN,
    UTM_KEYWORD,
    UTM_MEDIUM,
    UTM_SOURCE,
    SIGNUP_REFERRER,
    LANDING_PAGE
};
